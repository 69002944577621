type MatchData = {
    displaytitle: string;
    team1: string;
    team2: string;
    timestamp: string;
  };
  
  type DataFuturMatch = {
    jsonDataFuturMatch: MatchData[];
  };
  
  export const TabMatchNextMatch = ({ jsonDataFuturMatch }: DataFuturMatch) => {
  
    return (
      <table className="table-auto mb-5 mt-5">
        <thead className="border-b-2 border-b-2 border-blue-1000">
          <tr>
            <th className="p-3 text-white text-sm font-sans tracking-wide text-left">Equipe</th>
            <th className="p-3 text-white text-sm font-sans tracking-wide text-left">Compétition</th>
            <th className="p-3 text-white text-sm font-sans tracking-wide text-left">Heure</th>
          </tr>
        </thead>
        <tbody>
          {jsonDataFuturMatch.map((match: MatchData, index: number) => {
            return (
              <tr key={index} className="border-b-2 border-blue-1000">
                <td className="p-3 text-sm text-white">
                {match.team1 && match.team2 ? `${match.team1} vs ${match.team2}` : ''}
                </td>
                <td className="p-3 text-sm text-white">{match.displaytitle}</td>
                <td className="p-3 text-sm text-white">{formatTimestamp(match.timestamp)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  
  function formatTimestamp(timestamp: string): string {
    const date = new Date(parseInt(timestamp) * 1000);
    const formattedDate = date.toLocaleDateString("fr-FR");
    const formattedTime = date.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" });
  
    return `${formattedDate} à ${formattedTime}`;
  }
  
  