import { TabMatchNextMatch } from "../components/TabMatchNextMatch"
import { useState, useEffect } from "react"

const URL = "/api/getAllData"

type MatchData = {
  displaytitle: string;
  team1: string;
  team2: string;
  timestamp: string;
};

export const Home = () => {
  const [DataFuturMatch, setDataFuturMatch] = useState<MatchData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(URL);
      const json = await result.json();
      setDataFuturMatch(json);
    };

    fetchData();
  }, []);

  return (
    <div className="content-center">
      <div className="flex justify-center items-center box-content h-40">
        <h3 className="text-center items-center text-white font-sans">Bienvenue sur KCalendar, ce site permet de suivre l'actualité de la Karmine Corp en affichant les prochains matchs à venir ainsi que les résultats des derniers matchs.</h3>
      </div>

      <div className="flex justify-center">
        {DataFuturMatch.length === 0 ? (
          <p className="text-white font-sans flex justify-center">Aucun match à venir car la période est clame</p>
        ) : (
          <div>
            <div className="flex justify-center">
              <p className="text-white font-sans">Les prochains matchs sont :</p>
            </div>
            <TabMatchNextMatch jsonDataFuturMatch={DataFuturMatch} />
          </div>
        )}
      </div>
    </div>
  );
};
