import React, { useState } from 'react';
import './App.css';
import { Home } from './screens/Home';
import { LolTeam } from './screens/LolTeam';
import { RlTeam } from './screens/RLTeam';
import { ValorantTeam } from './screens/ValorantTeam';
import { SmashTeam } from './screens/SmashTeam';
import { TFTTeam } from './screens/TFTTeam';
import { TrackmaniaTeam } from './screens/TrackmaniaTeam';
import { Info } from './screens/Info';
import { Routes, Route, NavLink } from 'react-router-dom'
import { FiMenu } from 'react-icons/fi'; // Importez l'icône du menu hamburger (vous devez installer la bibliothèque des icônes correspondantes)


function App() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <div className='flex flex-col min-h-screen mt-10'>
      <div className='flex-grow'>
      <header className='mb-5'>
        <nav className='container flex justify-end'>
          {/* Affichez le menu hamburger uniquement sur mobile */}
          <div className='block sm:hidden'>
            <button
              type='button'
              className='text-slate-50 hover:text-slate-50 focus:outline-none focus:text-slate-50'
              onClick={toggleMobileMenu}
            >
              <FiMenu size={20} />
            </button>
          </div>

          {/* Affichez la navbar normale sur les écrans plus grands */}
          <div className='hidden sm:block'>
            <NavLink className="mr-3 text-sm text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/'>Accueil</NavLink>
            <NavLink className="mr-3 text-sm text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/lolteam'>Equipe LOL</NavLink>
            <NavLink className="mr-3 text-sm text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/rlteam'>Equipe RL</NavLink>
            <NavLink className="mr-3 text-sm text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/valorantteam'>Equipe Valorant</NavLink>
            <NavLink className="mr-3 text-sm text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/smashteam'>Equipe Smash</NavLink>
            <NavLink className="mr-3 text-sm text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/tftteam'>Equipe TFT</NavLink>
            <NavLink className="mr-3 text-sm text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/trackmaniateam'>Equipe Trackmania</NavLink>
          </div>
        </nav>

        {/* Affichez la navbar mobile lorsque showMobileMenu est vrai */}
        {showMobileMenu && (
          <div className='block sm:hidden'>
            <NavLink className="block py-2 px-4 text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/'>Accueil</NavLink>
            <NavLink className="block py-2 px-4 text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/lolteam'>Equipe LOL</NavLink>
            <NavLink className="block py-2 px-4 text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/rlteam'>Equipe RL</NavLink>
            <NavLink className="block py-2 px-4 text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/valorantteam'>Equipe Valorant</NavLink>
            <NavLink className="block py-2 px-4 text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/smashteam'>Equipe Smash</NavLink>
            <NavLink className="block py-2 px-4 text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/tftteam'>Equipe TFT</NavLink>
            <NavLink className="block py-2 px-4 text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/trackmaniateam'>Equipe Trackmania</NavLink>
          </div>
        )}
      </header>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/lolteam' element={<LolTeam />} />
        <Route path='/rlteam' element={<RlTeam />} />
        <Route path='/valorantteam' element={<ValorantTeam />} />
        <Route path='/smashteam' element={<SmashTeam />} />
        <Route path='/tftteam' element={<TFTTeam />} />
        <Route path='/trackmaniateam' element={<TrackmaniaTeam />} />
        <Route path='/info' element={<Info />} />
      </Routes>
      </div>
      <div className="container mx-auto border-t border-gray-300 mt-4"></div>
      <footer className="flex-shrink-0 py-4">
        <div className="container mx-auto text-center">
          <p className="text-gray-500">
            <NavLink className="block py-2 px-4 text-white" style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/info'>Information</NavLink>
            </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
